import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
// import { PATH_HOME } from 'src/routes/paths';
import { BASE_IMG } from '@utils/getImages';
import RouterLink from 'next/link';
import useBreakpoints from '@hooks/useBreakpoints';
import {
  varFadeInUp,
  MotionInView,
  varFadeInRight
} from '@components/Animate';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Box, Grid, Container, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return {
    root: {
      padding: theme.spacing(15, 0),
      backgroundImage:
        theme.palette.mode === 'light'
          ? `linear-gradient(180deg, ${alpha(theme.palette.grey[300], 0)} 0%, ${theme.palette.grey[300]
          } 100%)`
          : 'none'
    },
    content: {
      maxWidth: 520,
      margin: 'auto',
      textAlign: 'center',
      marginBottom: theme.spacing(10),
      [theme.breakpoints.up('md')]: {
        height: '100%',
        marginBottom: 0,
        textAlign: 'left',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingRight: theme.spacing(5)
      }
    },
    screen: {
      bottom: 0,
      maxWidth: 460,
      position: 'absolute'
    },
    screenLeft: { zIndex: 3 },
    screenRight: { zIndex: 1 },
    screenCenter: {
      position: 'relative',
      zIndex: 2,
      bottom: 20,
      transform: isRTL ? 'translateX(-24%)' : 'translateX(24%)',
      [theme.breakpoints.up('sm')]: {
        bottom: 40,
        transform: isRTL ? 'translateX(-32%)' : 'translateX(32%)'
      }
    }
  };
});

const variantScreenLeftMoblie = {
  initial: { x: '22%', y: -10, opacity: 0 },
  animate: { x: 0, y: 0, opacity: 1 }
};
const variantScreenRightMobile = {
  initial: { x: '26%', y: -30, opacity: 0 },
  animate: { x: '48%', y: -40, opacity: 1 }
};
const variantScreenLeft = {
  initial: { x: '30%', y: -30, opacity: 0 },
  animate: { x: 0, y: 0, opacity: 1 }
};
const variantScreenCenter = {
  initial: { opacity: 0 },
  animate: { opacity: 1 }
};
const variantScreenRight = {
  initial: { x: '34%', y: -50, opacity: 0 },
  animate: { x: '64%', y: -80, opacity: 1 }
};
const transition = { duration: 0.5, ease: 'easeOut' };

// ----------------------------------------------------------------------

HugePackElements.propTypes = {
  className: PropTypes.string
};

function HugePackElements({ className }) {
  const classes = useStyles();
  const theme = useTheme();
  const screenCenterAnimate = variantScreenCenter;

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} md={4} lg={5}>
            <div className={classes.content}>
              <Typography variant="h2" paragraph>
                An all-in-one tool for real estate
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                It is the first managing and financial software entirely built for commercial real-estate. Let's know more about it
              </Typography>
              <Button
                size="large"
                color="inherit"
                variant="outlined"
                sx={{ marginTop: '20px' }}
              >
                DOWNLOAD BROCHURE
              </Button>
            </div>
          </Grid>

          <Grid
            dir="ltr"
            item
            xs={12}
            md={8}
            lg={7}
            sx={{
              position: 'relative',
              pl: { sm: '16% !important', md: '0 !important' }
            }}
          >
            <MotionInView
              variants={
                screenCenterAnimate
              }
              transition={transition}
              className={clsx(classes.screen, {
                [classes.screenCenter]: true
              })}
            >
              <Box
                component="img"
                alt={`screen`}
                src="/static/images/Summary.png"
                variants={varFadeInUp}
                className="lazyload"
                sx={{ width: { xs: '80%', sm: '100%' } }}
              />
            </MotionInView>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default HugePackElements;
