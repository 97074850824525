import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as ScrollLink } from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Box, Container, Typography } from '@material-ui/core';
import { Copyright } from '@components/common';
// import {Logo} from '@components/common'

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(5, 0)
  }
}));

// ----------------------------------------------------------------------

Footer.propTypes = {
  className: PropTypes.string
};

function Footer({ className }) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={clsx(classes.root, className)}>
      {/* <ScrollLink to="move_top" spy={true} smooth={true}>
        <Box
            component="img"
            alt="logo"
            src="/static/brand/logo_single.svg"
            height={40}
            sx={{ mb: 1, mx: 'auto' }}
        />
      </ScrollLink> */}

      <Typography variant="caption">
        <Copyright />
      </Typography>
    </Container>
  );
}

export default Footer;
