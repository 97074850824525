import React, { useEffect } from 'react';
import Hero from './Hero';
import Footer from './Footer';
import DarkMode from './DarkMode';
// import Page from '@components/';
import Minimal from './Minimal';
import Advertisement from './Advertisement';
import CleanInterfaces from './CleanInterfaces';
import HugePackElements from './HugePackElements';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import HomeLayout from './HomeLayout';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: theme.palette.background.default
  }
}));

function LandingPageView() {
  const classes = useStyles();
  useEffect(() => {
    if (document) {
      document.body.style.overflow = "auto";
    }
  }, [])
  return (
    <HomeLayout>
    <Box
      className={classes.root}
    >
      <Hero />
      <div className={classes.content}>
        <Minimal />
        <HugePackElements />
        <CleanInterfaces
          title="Lots, counters & shares "
          description="Divide your buildings by lots, counts every square meeters and input shares accross tenants"
          buttonText="Know more about this"
          imgSrc="/static/images/Lots.png"
        />
        <DarkMode
          title="Budgets"
          description="Calculate your budgets including mortgages, rents, suppliers invoices and other costs and revenue"
          buttonText="Know more about this"
          imgSrc="/static/images/Budget.png"
        />
        <CleanInterfaces
          title="Tenants"
          description="Add every tenants to the solution, their contacts and other informations. Share them across organisations if necessary."
          buttonText="Know more about this"
          imgSrc="/static/images/Tenants.png"
        />
        <DarkMode
          title="Leases"
          description="Create and generate your leases, directly from IMMO. Send them to your tenants and sign them electronically"
          buttonText="Know more about this"
          imgSrc="/static/images/Leases.png"
        />
        <CleanInterfaces
          title="Suppliers"
          description="Add and invite all suppliers you are working with. Give them an visitors access so they can upload their invoices. Store all of their informations."
          buttonText="Know more about this"
          imgSrc="/static/images/Suppliers.png"
        />
        <DarkMode
          title="Invoices"
          description="Organize, genarate and automate your invoicing process. Save time every month"
          buttonText="Know more about this"
          imgSrc="/static/images/Invoices.png"
        />
        <CleanInterfaces
          title="Expenses"
          description="All your expenses in one place. Add any expenses note and assign them to the building and organisations of your choice"
          buttonText="Know more about this"
          imgSrc="/static/images/Expenses.png"
        />
        <DarkMode
          title="Finance analytics"
          description="Benefit from detailed and intelligent data analysis to make future business decision. Increase your profitability and efficiency every day !"
          buttonText="Know more about this"
          imgSrc="/static/images/Report.png"
        />
        <Advertisement />
        <Footer />
      </div>
    </Box>
    </HomeLayout>
  );
}

export default LandingPageView;
