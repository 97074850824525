import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { BASE_IMG } from '@utils/getImages';
import { varFadeInUp, MotionInView } from '@components/Animate';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, Button } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10)
  },
  content: {
    maxWidth: 520,
    margin: 'auto',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      position: 'absolute'
    }
  }
}));

CleanInterfaces.propTypes = {
  className: PropTypes.string
};

function CleanInterfaces({ className, title, description, buttonText, imgSrc }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <div className={classes.content}>


          <MotionInView variants={varFadeInUp}>
            <Typography variant="h2" paragraph>
              {title}
            </Typography>
          </MotionInView>

          <MotionInView variants={varFadeInUp}>
            <Typography
              gutterBottom
              variant="overline"
              sx={{ color: 'text.secondary', display: 'block' }}
            >
              {description}
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInUp} sx={{ mt: 5 }}>
            <Button
              size="large"
              color="inherit"
              variant="outlined"
            >
              {buttonText}
            </Button>
          </MotionInView>
        </div>

        <MotionInView variants={varFadeInUp} sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <Box
            component="img"
            alt="multipage"
            src={imgSrc}
            variants={varFadeInUp}
            sx={{ m: 'auto', width: '45%', maxHeight: '500px', margin: 'unset' }}
          />
        </MotionInView>
      </Container>
    </div>
  );
}

export default CleanInterfaces;
