import clsx from 'clsx';
import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Link,
  Button,
  AppBar,
  Toolbar,
  Container,
} from '@material-ui/core';
import useOffSetTop from '@hooks/useOffSetTop';
import { useRouter } from 'next/router';
import { useTranslation } from '@contexts/translation';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 96;

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    height: APP_BAR_MOBILE,
    transition: theme.transitions.create(['height', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    [theme.breakpoints.up('md')]: {
      height: APP_BAR_DESKTOP
    }
  },
  onScroll: {
    '& $toolbar': {
      backgroundColor: 'inherit'
    },
    '& $isHome': {
      color: theme.palette.text.primary
    },
    [theme.breakpoints.up('md')]: {
      '& $toolbar': {
        height: APP_BAR_DESKTOP - 20
      }
    }
  }
}));

// ----------------------------------------------------------------------

function TopBar() {
  const classes = useStyles();
  const offset = useOffSetTop(100);
  const router = useRouter();
  const { dictionary } = useTranslation();

  return (
    <AppBar
      color="transparent"
      className={clsx(classes.root, { [classes.onScroll]: offset })}
      sx={{ boxShadow: 'none', alignItems: 'flex-end' }}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        <Container
          maxWidth="lg"
          sx={{
            lineHeight: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button
            underline="none"
            variant="contained"
            onClick={() => router.push('/login')}
          >
            {dictionary['sign_in']}
          </Button>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
