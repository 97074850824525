import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
// import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import LazySize from './LazySize';
import { BASE_IMG } from '@utils/getImages';
// import flashFill from '@iconify-icons/eva/flash-fill';
import RouterLink from 'next/link';
// import { PATH_APP, PATH_HOME } from 'src/routes/paths';
import {
  varFadeIn,
  varWrapEnter,
  varFadeInUp,
  varFadeInRight
} from '@components/Animate';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Link, Container, Typography } from '@material-ui/core';
import { useRouter } from 'next/router';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#F2F3F5',
    [theme.breakpoints.up('md')]: {
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      display: 'flex',
      position: 'fixed',
      alignItems: 'center'
    }
  },
  content: {
    zIndex: 10,
    maxWidth: 520,
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      textAlign: 'left'
    }
  },
  heroOverlay: {
    zIndex: 9,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    background: 'rgb(4, 49, 118)',
    opacity: '0.7 !important'
  },
  heroImg: {
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 8,
    width: '100%',
    margin: 'auto',
    position: 'absolute',
    filter: 'drop-shadow(rgba(0, 0, 0, 0.48) 40px 80px 80px)',
    [theme.breakpoints.up('lg')]: {
      right: '8%',
      width: 'auto',
      height: '40vh'
    }
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
    color: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start'
    }
  },
  listIcon: {
    display: 'flex',
    marginTop: theme.spacing(5),
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start'
    },
    '& > :not(:last-of-type)': {
      marginRight: theme.spacing(1.5)
    }
  }
}));

// ----------------------------------------------------------------------

const getImg = (width) =>
  `${BASE_IMG}w_${width}/v1611472901/upload_minimal/home/hero.png`;

Hero.propTypes = {
  className: PropTypes.string
};

function Hero({ className }) {
  const classes = useStyles();
  const router = useRouter();

  return (
    <>
      <motion.div
        initial="initial"
        animate="animate"
        variants={varWrapEnter}
        className={clsx(classes.root, className)}
      >
        <motion.div
          // alt="overlay"
          // src="/static/images/overlay.svg"
          variants={varFadeIn}
          className={classes.heroOverlay}
        />

        <motion.img
          alt="hero"
          src="/static/images/dashboard.png"
          variants={varFadeInUp}
          className={classes.heroImg}
        />

        <Container maxWidth="lg">
          <div className={classes.content}>
            <motion.div variants={varFadeInRight}>
              <Typography variant="h1" sx={{ color: 'common.white' }}>
                The real-estate managing software
              </Typography>
            </motion.div>

            <motion.div variants={varFadeInRight}>
              <Box component="p" sx={{ color: 'common.white', py: 5 }}>
                Optimize the entire management of your real estate. Find all the features necessary for an efficient management in a single all-in-one tool
              </Box>

              <Box component="p" sx={{ color: 'common.white', marginBottom: 1 }}>
                15 days trial
              </Box>
              <Box component="p" sx={{ color: 'common.white', marginBottom: 1 }}>
                Easy and fast setup
              </Box>
              <Box component="p" sx={{ color: 'common.white', marginBottom: 1 }}>
                No commitment
              </Box>
              <Box component="p" sx={{ color: 'common.white', marginBottom: 4 }}>
                No credit card required
              </Box>
            </motion.div>

            <motion.div variants={varFadeInRight} >
              <Button
                size="large"
                variant="outlined"
                color="inherit"
                sx={{ color: 'white' }}
                onClick={() => router.push('/signup')}
              >
                Get Started
              </Button>
            </motion.div>

          </div>
        </Container>
      </motion.div>
      <Box sx={{ height: { md: '100vh' } }} />
    </>
  );
}

export default Hero;
